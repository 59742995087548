export const COMMONS_I18N_ENTRIES = {
  gallery: {
    es: '<p>Galería</p>',
    en: '<p>Gallery</p>',
    pt: '<p>Galeria</p>',
    fr: '<p>Galerie</p>',
    eus: '<p>Galeria</p>',
    cat: '<p>Galeria</p>',
  },

  loading: {
    es: '<p>Cargando...</p>',
    en: '<p>Loading...</p>',
    pt: '<p>A carregar...</p>',
    fr: '<p>Chargement en cours</p>',
    eus: '<p>Kargatzen...</p>',
    cat: '<p>S\'està carregant...</p>',
  },

  company: {
    es: '<p>empresa</p>',
    en: '<p>company</p>',
    pt: '<p>empresa</p>',
    fr: '<p>entreprise</p>',
    eus: '<p>Enpresa</p>',
    cat: '<p>empresa</p>',
  },

  professionals: {
    es: '<p>Profesionales</p>',
    en: '<p>Professionals</p>',
    pt: '<p>Profissionais</p>',
    fr: '<p>Professionnels</p>',
    eus: '<p>Profesionalak</p>',
    cat: '<p>Professionals</p>',
  },

  user: {
    es: '<p>usuario</p>',
    en: '<p>user</p>',
    pt: '<p>utilizador</p>',
    fr: '<p>utilisateur</p>',
    eus: '<p>erabiltzailea</p>',
    cat: '<p>usuari</p>',
  },

  viewMore: {
    es: '<p>ver más</p>',
    en: '<p>view more</p>',
    pt: '<p>ver mais</p>',
    fr: '<p>Voir plus</p>',
    eus: '<p>ikusi gehiago</p>',
    cat: '<p>mostra més</p>',
  },

  search: {
    es: '<p>Buscar</p>',
    en: '<p>Search</p>',
    pt: '<p>Pesquisar</p>',
    fr: '<p>Chercher</p>',
    eus: '<p>Bilatu</p>',
    cat: '<p>Cerca</p>',
  },

  name: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  nameDepartment2: {
    es: '<p>Nombre para el departamento</p>',
    en: '<p>Department name</p>',
    pt: '<p>Nome para o departamento</p>',
    fr: '<p>Nom du département</p>',
    eus: '<p>Departamentuaren izena</p>',
    cat: '<p>Nom per al departament</p>',
  },

  emailRequired: {
    es: '<p>El correo electrónico es obligatorio</p>',
    en: '<p>Email is required</p>',
    pt: '<p>O e-mail é obrigatório</p>',
    fr: '<p>L\'e-mail est requis</p>',
    eus: '<p>Helbide elektronikoa beharrezkoa da</p>',
    cat: '<p>L\'adreça electrònica és obligatòria</p>',
  },

  requestAdminUser1: {
    es: '<p>Datos usuario administrador 1</p>',
    en: '<p>Administrator user 1 details</p>',
    pt: '<p>Dados do utilizador administrador 1</p>',
    fr: '<p>Données utilisateur administrateur 1</p>',
    eus: '<p>1. erabiltzaile administratzailearen datuak</p>',
    cat: '<p>Dades usuari administrador 1</p>',
  },

  requestAdminUser2: {
    es: '<p>Datos usuario administrador 2</p>',
    en: '<p>Administrator user 2 details</p>',
    pt: '<p>Dados do utilizador administrador 2</p>',
    fr: '<p>Données utilisateur administrateur 2</p>',
    eus: '<p>2. erabiltzaile administratzailearen datuak</p>',
    cat: '<p>Dades usuari administrador 2</p>',
  },

  country: {
    es: '<p>País</p>',
    en: '<p>Country</p>',
    pt: '<p>País</p>',
    fr: '<p>Pays</p>',
    eus: '<p>Herrialdea</p>',
    cat: '<p>País</p>',
  },

  nifRequired: {
    es: '<p>El NIF es obligatorio</p>',
    en: '<p>Tax ID is required</p>',
    pt: '<p>O campo NIF é obrigatório</p>',
    fr: '<p>Le NIF est requis</p>',
    eus: '<p>IFZa beharrezkoa da</p>',
    cat: '<p>El NIF és obligatori</p>',
  },

  countryRequired: {
    es: '<p>El país es obligatorio</p>',
    en: '<p>Country is required</p>',
    pt: '<p>O campo País é obrigatório</p>',
    fr: '<p>Le pays est requis</p>',
    eus: '<p>Herrialdea beharrezkoa da</p>',
    cat: '<p>El país és obligatori</p>',
  },

  businesNameRequired: {
    es: '<p>La razón social es obligatoria</p>',
    en: '<p>Company Name is required</p>',
    pt: '<p>O campo Nome da empresa é obrigatório</p>',
    fr: '<p>La raison sociale est requise</p>',
    eus: '<p>Sozietate izena beharrezkoa da</p>',
    cat: '<p>La raó social és obligatòria</p>',
  },

  addressRequired: {
    es: '<p>La dirección es obligatoria</p>',
    en: '<p>Address is required</p>',
    pt: '<p>O campo Endereço é obrigatório</p>',
    fr: '<p>L\'adresse est requise</p>',
    eus: '<p>Helbidea beharrezkoa da</p>',
    cat: '<p>L\'adreça és obligatòria</p>',
  },

  contactPersonRequired: {
    es: '<p>Una persona de contacto es obligatorio</p>',
    en: '<p>A contact person is required</p>',
    pt: '<p>O campo Pessoa de contacto é obrigatório</p>',
    fr: '<p>Au moins une personne de contact est requise</p>',
    eus: '<p>Harremanetarako pertsona beharrezkoa da</p>',
    cat: '<p>La persona de contacte és obligatòria</p>',
  },

  phoneRequired: {
    es: '<p>El número de teléfono es obligatorio</p>',
    en: '<p>Phone number is required</p>',
    pt: '<p>O campo Número de telefone é obrigatório</p>',
    fr: '<p>Un numéro de téléphone est requis</p>',
    eus: '<p>Telefono zenbakia beharrezkoa da</p>',
    cat: '<p>El número de telèfon és obligatori</p>',
  },

  surname: {
    es: '<p>Apellidos</p>',
    en: '<p>Last name</p>',
    pt: '<p>Apelido</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Abizenak</p>',
    cat: '<p>Cognoms</p>',
  },

  save: {
    es: '<p>Guardar</p>',
    en: '<p>Save</p>',
    pt: '<p>Guardar</p>',
    fr: '<p>Sauvegarder</p>',
    eus: '<p>Gorde</p>',
    cat: '<p>Desa</p>',
  },

  add: {
    es: '<p>Añadir</p>',
    en: '<p>Add</p>',
    pt: '<p>Adicionar</p>',
    fr: '<p>Ajouter</p>',
    eus: '<p>Gehitu</p>',
    cat: '<p>Afegeix</p>',
  },

  cancel: {
    es: '<p>cancelar</p>',
    en: '<p>cancel</p>',
    pt: '<p>cancelar</p>',
    fr: '<p>annuler</p>',
    eus: '<p>Utzi</p>',
    cat: '<p>cancel·la</p>',
  },

  continue: {
    es: '<p>continuar</p>',
    en: '<p>continue</p>',
    pt: '<p>continuar</p>',
    fr: '<p>continuer</p>',
    eus: '<p>JARRAITU</p>',
    cat: '<p>continua</p>',
  },

  models: {
    es: '<p>modelos 3D</p>',
    en: '<p>3D models</p>',
    pt: '<p>modelos 3D</p>',
    fr: '<p>modèles 3D</p>',
    eus: '<p>3D EREDUAK</p>',
    cat: '<p>models 3D</p>',
  },

  models_text: {
    es: '<p>modelos</p>',
    en: '<p>models</p>',
    pt: '<p>modelos</p>',
    fr: '<p>modèles</p>',
    eus: '<p>ereduak</p>',
    cat: '<p>models</p>',
  },

  badEmail: {
    es: '<p>Correo erróneo</p>',
    en: '<p>Incorrect email</p>',
    pt: '<p>E-mail incorreto</p>',
    fr: '<p>Adresse erronée</p>',
    eus: '<p>Helbide elektroniko okerra</p>',
    cat: '<p>El correu és erroni</p>',
  },

  notCorporateMail: {
    es: '<p>El email introducido no es corporativo</p>',
    en: '<p>The email you entered is not a corporate email</p>',
    pt: '<p>O e-mail introduzido não é corporativo</p>',
    fr: '<p>L\'e-mail saisi n\'appartient pas à l\'entreprise</p>',
    eus: '<p>Sartu duzun helbide elektronikoa ez da korporatiboa</p>',
    cat: '<p>L\'adreça de correu electrònic introduïda no és corporativa</p>',
  },

  minPasswordError: {
    es: '<p>* Contraseña mínimo 8 caracteres con un carácter especial</p>',
    en: '<p>* Password must have at least 8 characters and a special character</p>',
    pt: '<p>* A palavra-passe deve ter, no mínimo, 8 caracteres com um caráter especial</p>',
    fr: '<p>* Le mot de passe doit avoir au moins 8 caractères avec un caractère spécial</p>',
    eus: '<p>* Pasahitzak gutxienez 8 karaktere izan behar ditu karaktere berezi batekin</p>',
    cat: '<p>* La contrasenya ha de tenir, com a mínim, 8 caràcters amb un caràcter especial</p>',
  },

  notEqualPasswords: {
    es: '<p>Las contraseñas no coinciden</p>',
    en: '<p>Passwords do not match</p>',
    pt: '<p>As palavras-passe não coincidem</p>',
    fr: '<p>Les mots de passe ne sont pas identiques</p>',
    eus: '<p>Pasahitzak ez datoz bat</p>',
    cat: '<p>Les contrasenyes no coincideixen</p>',
  },

  termsOfUse: {
    es: '<p>Términos de uso</p>',
    en: '<p>Terms of use</p>',
    pt: '<p>Termos de utilização</p>',
    fr: '<p>Conditions générales d\'utilisation</p>',
    eus: '<p>Erabilera baldintzak</p>',
    cat: '<p>Termes d\'ús</p>',
  },

  privacyPolicy: {
    es: '<p>Política de privacidad</p>',
    en: '<p>Privacy policy</p>',
    pt: '<p>Política de privacidade</p>',
    fr: '<p>Politique de confidentialité</p>',
    eus: '<p>Pribatutasun politika</p>',
    cat: '<p>Política de privadesa</p>',
  },

  cookiePolicies: {
    es: '<p>Política de cookies</p>',
    en: '<p>Cookies policy</p>',
    pt: '<p>Política de cookies</p>',
    fr: '<p>Politique de cookies</p>',
    eus: '<p>Cookie politika</p>',
    cat: '<p>Política de galetes</p>',
  },

  delete: {
    es: '<p>Eliminar</p>',
    en: '<p>Delete</p>',
    pt: '<p>Eliminar</p>',
    fr: '<p>Supprimer</p>',
    eus: '<p>Ezabatu</p>',
    cat: '<p>Elimina</p>',
  },

  searcher: {
    es: '<p>Buscador</p>',
    en: '<p>Search engine</p>',
    pt: '<p>Motor de pesquisa</p>',
    fr: '<p>Moteur de recherche</p>',
    eus: '<p>Bilatzailea</p>',
    cat: '<p>Cercador</p>',
  },

  clickAnd: {
    es: '<p>Haz click y</p>',
    en: '<p>Click and</p>',
    pt: '<p>Clique e</p>',
    fr: '<p>Cliquez sur et</p>',
    eus: '<p>Egin klik eta</p>',
    cat: '<p>Fes clic i</p>',
  },

  then: {
    es: '<p>A CONTINUACIÓN:</p>',
    en: '<p>NEXT:</p>',
    pt: '<p>A SEGUIR:</p>',
    fr: '<p>ENSUITE :</p>',
    eus: '<p>AURRERA EGIN</p>',
    cat: '<p>A CONTINUACIÓ:</p>',
  },

  more3dModels: {
    es: '<p>Visualiza más modelos 3D</p>',
    en: '<p>Display more 3D models</p>',
    pt: '<p>Visualize mais modelos 3D</p>',
    fr: '<p>Visualisez plus de modèles 3D</p>',
    eus: '<p>Bistaratu 3D eredu gehiago</p>',
    cat: '<p>Mostra més models 3D</p>',
  },

  mostPopulars: {
    es: '<p>Más Populares</p>',
    en: '<p>Most popular</p>',
    pt: '<p>Mais populares</p>',
    fr: '<p>Les plus populaires</p>',
    eus: '<p>Ezagunenak</p>',
    cat: '<p>Més populars</p>',
  },

  nextPage: {
    es: '<p>Ir a la página siguiente</p>',
    en: '<p>Go to next page</p>',
    pt: '<p>Ir para a página seguinte</p>',
    fr: '<p>Aller à la page suivante</p>',
    eus: '<p>Joan hurrengo orrialdera</p>',
    cat: '<p>Ves a la pàgina següent</p>',
  },

  previusPage: {
    es: '<p>Volver a la página anterior</p>',
    en: '<p>Go back to previous page</p>',
    pt: '<p>Voltar à página anterior</p>',
    fr: '<p>Retourner à la page précédente</p>',
    eus: '<p>Joan aurreko orrialdera</p>',
    cat: '<p>Torna a la pàgina anterior</p>',
  },

  otherModels: {
    es: '<p>Visualizar otros modelos 3D</p>',
    en: '<p>Display other 3D models</p>',
    pt: '<p>Visualizar outros modelos 3D</p>',
    fr: '<p>Visualiser d\'autres modèles 3D</p>',
    eus: '<p>Bistaratu beste 3D eredu batzuk</p>',
    cat: '<p>Mostra altres models 3D</p>',
  },

  d3Models: {
    es: '<p>Modelos 3d</p>',
    en: '<p>3D Models</p>',
    pt: '<p>Modelos 3D</p>',
    fr: '<p>Modèles 3D</p>',
    eus: '<p>3D ereduak</p>',
    cat: '<p>Models 3D</p>',
  },

  eCommerce: {
    es: '<p>Comercio electrónico</p>',
    en: '<p>E-commerce</p>',
    pt: '<p>Comércio eletrónico</p>',
    fr: '<p>E-commerce</p>',
    eus: '<p>Merkataritza elektronikoa</p>',
    cat: '<p>Comerç electrònic</p>',
  },

  engineering: {
    es: '<p>Ingeniería</p>',
    en: '<p>Engineering</p>',
    pt: '<p>Engenharia</p>',
    fr: '<p>Ingénierie</p>',
    eus: '<p>Ingeniaritza</p>',
    cat: '<p>Enginyeria</p>',
  },

  security: {
    es: '<p>Seguridad</p>',
    en: '<p>Safety</p>',
    pt: '<p>Segurança</p>',
    fr: '<p>Sécurité</p>',
    eus: '<p>Segurtasuna</p>',
    cat: '<p>Seguretat</p>',
  },

  healthCare: {
    es: '<p>Salud</p>',
    en: '<p>Healthcare</p>',
    pt: '<p>Saúde</p>',
    fr: '<p>Santé</p>',
    eus: '<p>Osasuna</p>',
    cat: '<p>Salut</p>',
  },

  education: {
    es: '<p>Educación</p>',
    en: '<p>Education</p>',
    pt: '<p>Educação</p>',
    fr: '<p>Éducation</p>',
    eus: '<p>Hezkuntza</p>',
    cat: '<p>Educació</p>',
  },

  various: {
    es: '<p>Varios</p>',
    en: '<p>Miscellaneous</p>',
    pt: '<p>Vários</p>',
    fr: '<p>Divers</p>',
    eus: '<p>Beste batzuk</p>',
    cat: '<p>Miscel·lània</p>',
  },

  gastronomy: {
    es: '<p>Gastronomía</p>',
    en: '<p>Gastronomy</p>',
    pt: '<p>Gastronomia</p>',
    fr: '<p>Gastronomie</p>',
    eus: '<p>Gastronomia</p>',
    cat: '<p>Gastronomia</p>',
  },

  artAndCulture: {
    es: '<p>Arte y cultura</p>',
    en: '<p>Arts and Culture</p>',
    pt: '<p>Arte e cultura</p>',
    fr: '<p>Arts et culture</p>',
    eus: '<p>Artea eta kultura</p>',
    cat: '<p>Art i cultura</p>',
  },

  fares: {
    es: '<p>Tarifas</p>',
    en: '<p>Plans</p>',
    pt: '<p>Tarifas</p>',
    fr: '<p>Tarifs</p>',
    eus: '<p>Tarifak</p>',
    cat: '<p>Tarifes</p>',
  },

  logout: {
    es: '<p>Cerrar sesión</p>',
    en: '<p>Log out</p>',
    pt: '<p>Terminar sessão</p>',
    fr: '<p>Se déconnecter</p>',
    eus: '<p>Itxi saioa</p>',
    cat: '<p>Tanca sessió</p>',
  },

  news: {
    es: '<p>Novedades</p>',
    en: '<p>News</p>',
    pt: '<p>Novidades</p>',
    fr: '<p>Nouveautés</p>',
    eus: '<p>Berrikuntzak</p>',
    cat: '<p>Novetats</p>',
  },

  incidences: {
    es: '<p>Incidencias</p>',
    en: '<p>Incidents</p>',
    pt: '<p>Incidências</p>',
    fr: '<p>Incidents</p>',
    eus: '<p>Arazoak</p>',
    cat: '<p>Incidències</p>',
  },

  anyIncidences: {
    es: '<p>¿Tienes alguna incidencia?</p>',
    en: '<p>Do you have any incidents to report?</p>',
    pt: '<p>Tem alguma incidência?</p>',
    fr: '<p>Avez-vous un incident à signaler ?</p>',
    eus: '<p>Arazorik baduzu?</p>',
    cat: '<p>Tens cap incidència?</p>',
  },

  writeIncidence: {
    es: '<p>Escribir Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escrever incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Eman arazo baten berri</p>',
    cat: '<p>Escriu una incidència</p>',
  },

  statusIncidence: {
    es: '<p>Estado de incidencias</p>',
    en: '<p>Incident status</p>',
    pt: '<p>Estado de incidências</p>',
    fr: '<p>État des incidents</p>',
    eus: '<p>Estado de incidencias</p>',
    cat: '<p>Estat de les incidències</p>',
  },

  sendIncidence: {
    es: '<p>Enviar Incidencia</p>',
    en: '<p>Submit Incident</p>',
    pt: '<p>Enviar incidência</p>',
    fr: '<p>Envoyer rapport d\'incident</p>',
    eus: '<p>BIDALI ARAZOA</p>',
    cat: '<p>Envia incidència</p>',
  },

  writeTheIncidence: {
    es: '<p>Escribe Incidencia</p>',
    en: '<p>Report an incident</p>',
    pt: '<p>Escreva a incidência</p>',
    fr: '<p>Signaler incident</p>',
    eus: '<p>Idatzi arazoa hemen</p>',
    cat: '<p>Escriu aquí la incidència</p>',
  },

  resolved: {
    es: '<p>Resuelta</p>',
    en: '<p>Resolved</p>',
    pt: '<p>Resolvida</p>',
    fr: '<p>Résolu</p>',
    eus: '<p>Konponduta</p>',
    cat: '<p>Resolta</p>',
  },

  review: {
    es: '<p>En revisión</p>',
    en: '<p>Under review</p>',
    pt: '<p>Em revisão</p>',
    fr: '<p>En cours de traitement</p>',
    eus: '<p>Berrikusketan</p>',
    cat: '<p>En revisió</p>',
  },

  noIncidences: {
    es: '<p>Actualmente no tienes incidencias</p>',
    en: '<p>You currently have no incidents in progress</p>',
    pt: '<p>Atualmente, não tem incidências</p>',
    fr: '<p>Actuellement vous n\'avez aucun incident en cours</p>',
    eus: '<p>Actualmente no tienes incidencias</p>',
    cat: '<p>Actualment no tens incidències</p>',
  },

  incidentSentSuccessfully: {
    es: '<p>Incidencia enviada correctamente</p>',
    en: '<p>Your incident has been submitted successfully</p>',
    pt: '<p>Incidência enviada corretamente</p>',
    fr: '<p>Rapport d\'incident envoyé correctement</p>',
    eus: '<p>Arazoa behar bezala bidali da</p>',
    cat: '<p>La incidència s\'ha enviat correctament</p>',
  },

  contactIncidence: {
    es: '<p>Nos pondremos en contacto en la mayor brevedad posible.</p>',
    en: '<p>We will contact you as soon as possible.</p>',
    pt: '<p>Entraremos em contacto consigo o mais brevemente possível.</p>',
    fr: '<p>Nous vous contacterons dans les meilleurs délais.</p>',
    eus: '<p>Zurekin harremanetan jarriko gara ahalik eta lasterren.</p>',
    cat: '<p>Ens posarem en contacte amb tu al més aviat.</p>',
  },

  deleteModelText: {
    es: '<p>Selecciona la foto que deseas eliminar. La foto se eliminará permanentemente</p>',
    en: '<p>Select the photo you wish to delete. The photo will be permanently deleted</p>',
    pt: '<p>Selecione a fotografia que pretende eliminar. A fotografia será eliminada permanentemente</p>',
    fr: '<p>Sélectionnez la photo que vous souhaitez éliminer. La photo sera éliminée de façon permanente</p>',
    eus: '<p>Hautatu ezabatu nahi duzun argazkia. Argazkia betiko ezabatuko da</p>',
    cat: '<p>Selecciona la foto que vols eliminar. La foto s\'eliminarà de manera permanent</p>',
  },

  deleteModelButton: {
    es: '<p>Cancelar</p>',
    en: '<p>Cancel</p>',
    pt: '<p>Cancelar</p>',
    fr: '<p>Annuler</p>',
    eus: '<p>Utzi</p>',
    cat: '<p>Cancel·la</p>',
  },

  iotaDownloadFormat: {
    es: '<p>Formato</p>',
    en: '<p>Format</p>',
    pt: '<p>Formato</p>',
    fr: '<p>Format</p>',
    eus: '<p>Formatua</p>',
    cat: '<p>Format</p>',
  },

  iotaDownloadDate: {
    es: '<p>Fecha</p>',
    en: '<p>Date</p>',
    pt: '<p>Data</p>',
    fr: '<p>Date</p>',
    eus: '<p>Data</p>',
    cat: '<p>Data</p>',
  },

  email: {
    es: '<p>Email</p>',
    en: '<p>E-mail</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>E-mail</p>',
    eus: '<p>Helbide elektronikoa</p>',
    cat: '<p>Adreça electrònica</p>',
  },

  password: {
    es: '<p>Contraseña</p>',
    en: '<p>Password</p>',
    pt: '<p>Palavra-passe</p>',
    fr: '<p>Mot de passe</p>',
    eus: '<p>Pasahitza</p>',
    cat: '<p>Contrasenya</p>',
  },

  passwordRequired: {
    es: '<p>La contraseña es obligatoria</p>',
    en: '<p>Password is required</p>',
    pt: '<p>O campo Palavra-passe é obrigatório</p>',
    fr: '<p>Un mot de passe est requis</p>',
    eus: '<p>Pasahitza beharrezkoa da</p>',
    cat: '<p>La contrasenya és obligatòria</p>',
  },

  fileUploadWish: {
    es: '<p>¿Deseas adjuntar algún archivo de imagen?</p>',
    en: '<p>Do you want to attach an image file?</p>',
    pt: '<p>Pretende anexar um ficheiro de imagem?</p>',
    fr: '<p>Souhaitez-vous joindre un fichier d\'image?</p>',
    eus: '<p>Irudi fitxategirik erantsi nahi duzu?</p>',
    cat: '<p>Vols adjuntar algun fitxer d\'imatge?</p>',
  },

  maxFileSize: {
    es: '<p>(máx. 5MB)</p>',
    en: '<p>(max. 5MB)</p>',
    pt: '<p>(máx. 5 MB)</p>',
    fr: '<p>(5 Mo maxi.)</p>',
    eus: '<p>(geh. 5MB)</p>',
    cat: '<p>(màx. 5 MB)</p>',
  },

  modelPopulars: {
    es: '<p>Modelos 3D Más Populares</p>',
    en: '<p>Most popular 3D models</p>',
    pt: '<p>Modelos 3D mais populares</p>',
    fr: '<p>Modèles 3D les plus populaires</p>',
    eus: '<p>3D eredu ezagunenak</p>',
    cat: '<p>Models 3D més populars</p>',
  },

  measureUnitMeters: {
    es: '<p>Metros</p>',
    en: '<p>Meters</p>',
    pt: '<p>Metros</p>',
    fr: '<p>Mètres</p>',
    eus: '<p>Metroak</p>',
    cat: '<p>Metres</p>',
  },

  cuentaEmpresa_usuarios_data5: {
    es: '<p>Rol</p>',
    en: '<p>Role</p>',
    pt: '<p>Cargo</p>',
    fr: '<p>Poste</p>',
    eus: '<p>Rola</p>',
    cat: '<p>Càrrec</p>',
  },

  cuentaEmpresa_usuarios_data6: {
    es: '<p>Rol:</p>',
    en: '<p>Role:</p>',
    pt: '<p>Cargo:</p>',
    fr: '<p>Poste:</p>',
    eus: '<p>Rola:</p>',
    cat: '<p>Càrrec:</p>',
  },

  cuentaEmpresa_usuarios_data2: {
    es: '<p>DPTO.</p>',
    en: '<p>DEPT.</p>',
    pt: '<p>DPT.</p>',
    fr: '<p>Dépt.</p>',
    eus: '<p>DPTU.</p>',
    cat: '<p>DEPT.</p>',
  },

  cuentaEmpresa_usuarios_data3: {
    es: '<p>Opciones</p>',
    en: '<p>Options</p>',
    pt: '<p>Opções</p>',
    fr: '<p>Options</p>',
    eus: '<p>Aukerak</p>',
    cat: '<p>Opcions</p>',
  },

  deleteSelection: {
    es: '<p>Eliminar selección</p>',
    en: '<p>Delete selection</p>',
    pt: '<p>Eliminar seleção</p>',
    fr: '<p>Supprimer la sélection</p>',
    eus: '<p>Ezabatu hautaketa</p>',
    cat: '<p>Elimina la selecció</p>',
  },

  selectCategory: {
    es: '<p>Selecciona una categoría</p>',
    en: '<p>Select a category</p>',
    pt: '<p>Selecione uma categoria</p>',
    fr: '<p>Sélectionnez une catégorie</p>',
    eus: '<p>Aukeratu kategoria bat</p>',
    cat: '<p>Selecciona una categoria</p>',
  },

  notEqualPasswordsOrWrongMail: {
    es: '<p>Las contraseñas no coinciden o el correo electrónico introducido no es válido</p>',
    en: '<p>The passwords do not match or the email address is incorrect</p>',
    pt: '<p>As palavras-passe não coincidem ou o e-mail introduzido não é válido</p>',
    fr: '<p>Les mots de passe ne sont pas identiques ou l\'adresse électronique saisie n\'est pas valide.</p>',
    eus: '<p>Pasahitzak ez datoz bat edo sartu duzun helbide elektronikoak ez du balio.</p>',
    cat: '<p>Les contrasenyes no coincideixen o l\'adreça de correu electrònic introduïda no és vàlida</p>',
  },

  myPromotions_data1: {
    es: '<p>Actualmente no hay promociones activas.</p>',
    en: '<p>There are no active promotions at this time.</p>',
    pt: '<p>Atualmente não há promoções ativas.</p>',
    fr: '<p>Il n\'y a actuellement aucune promotion disponible.</p>',
    eus: '<p>Une honetan ez dago promoziorik indarrean.</p>',
    cat: '<p>Actualment no hi ha cap promoció activa.</p>',
  },

  myPromotions_data2: {
    es: '<p>BLACK</p>',
    en: '<p>BLACK</p>',
    pt: '<p>BLACK</p>',
    fr: '<p>BLACK</p>',
    eus: '<p>BLACK</p>',
    cat: '<p>BLACK</p>',
  },

  myPromotions_data3: {
    es: '<p>FRIDAY</p>',
    en: '<p>FRIDAY</p>',
    pt: '<p>FRIDAY</p>',
    fr: '<p>FRIDAY</p>',
    eus: '<p>FRIDAY</p>',
    cat: '<p>FRIDAY</p>',
  },

  myPromotions_data4: {
    es: '<p>6,66€</p>',
    en: '<p>€6.66</p>',
    pt: '<p>6,66 €</p>',
    fr: '<p>6,66€</p>',
    eus: '<p>6,66 €</p>',
    cat: '<p>6,66 €</p>',
  },

  myPromotions_data5: {
    es: '<p>Válido hasta XX/XX/XXX</p>',
    en: '<p>Valid until XX/XX/XXX</p>',
    pt: '<p>Válido até XX/XX/XXX</p>',
    fr: '<p>Valable jusqu\'au XX/XX/XXX</p>',
    eus: '<p>XX/XX/XXX arte balioduna</p>',
    cat: '<p>Vàlid fins a XX/XX/XXX</p>',
  },

  myPromotions_data6: {
    es: '<p><span class="i18n-bold">Herramientas incluidas: </span>%X%</p>',
    en: '<p><span class="i18n-bold">Tools included: </span>%X%</p>',
    pt: '<p><span class="i18n-bold">Ferramentas incluídas: </span>%X%</p>',
    fr: '<p><span class="i18n-bold">Outils inclus: </span>%X%</p>',
    eus: '<p><span class="i18n-bold">Honako tresnak sartzen dira: </span>%X%</p>',
    cat: '<p><span class="i18n-bold">Eines incloses: </span>%X%</p>',
  },

  myPromotions_data8: {
    es: '<p>RESUMEN INFORMATIVO TARIFAS</p>',
    en: '<p>INFORMATIVE SUMMARY OF PLANS</p>',
    pt: '<p>RESUMO INFORMATIVO TARIFAS</p>',
    fr: '<p>RÉCAPITULATIF DES TARIFS</p>',
    eus: '<p>KONTRATATUTAKO TARIFEN INFORMAZIO</p>',
    cat: '<p>RESUM INFORMATIU DE TARIFES</p>',
  },

  myPromotions_data9: {
    es: '<p>CONTRATADAS</p>',
    en: '<p>PURCHASED</p>',
    pt: '<p>CONTRATADAS</p>',
    fr: '<p>SOUSCRITS</p>',
    eus: '<p>LABURPENA</p>',
    cat: '<p>CONTRACTADES</p>',
  },

  myPromotions_data13: {
    es: '<p>Actualmente no tienes ningún método de pago registrado.</p>',
    en: '<p>You do not currently have a registered payment method.</p>',
    pt: '<p>Atualmente não tem nenhum método de pagamento registado.</p>',
    fr: '<p>Vous n\'avez actuellement aucun mode de paiement sauvegardé.</p>',
    eus: '<p>Oraindik ez duzu ordainketa metodorik erregistratu.</p>',
    cat: '<p>En aquests moments no hi ha cap mètode de pagament registrat.</p>',
  },

  myPromotions_data14: {
    es: '<p>AÑADIR UN NUEVO MÉTODO DE PAGO</p>',
    en: '<p>ADD A NEW PAYMENT METHOD</p>',
    pt: '<p>ACRESCENTAR UM NOVO MÉTODO DE PAGAMENTO</p>',
    fr: '<p>AJOUTER UN NOUVEAU MODE DE PAIEMENT</p>',
    eus: '<p>GEHITU ORDAINKETA METODO BERRIA</p>',
    cat: '<p>AFEGEIX UN NOU MÈTODE DE PAGAMENT</p>',
  },

  myPromotions_data15: {
    es: '<p>Seleccionar un método de pago</p>',
    en: '<p>Select a payment method</p>',
    pt: '<p>Selecionar um método de pagamento</p>',
    fr: '<p>Sélectionnez votre mode de paiement</p>',
    eus: '<p>Hautatu ordainketa metodoa</p>',
    cat: '<p>Selecciona un mètode de pagament</p>',
  },

  myPromotions_data17: {
    es: '<p>Modificar</p>',
    en: '<p>Modify</p>',
    pt: '<p>Modificar</p>',
    fr: '<p>Modifier</p>',
    eus: '<p>Aldatu</p>',
    cat: '<p>Modifica</p>',
  },

  myPromotions_data18: {
    es: '<p>Añadir un nuevo método de pago</p>',
    en: '<p>Add a new payment method</p>',
    pt: '<p>Acrescentar um novo método de pagamento</p>',
    fr: '<p>Ajouter un nouveau mode de paiement</p>',
    eus: '<p>Gehitu ordainketa metodo berria</p>',
    cat: '<p>Afegeix un nou mètode de pagament</p>',
  },

  myPromotions_data20: {
    es: '<p>Historial de pago</p>',
    en: '<p>Payment record</p>',
    pt: '<p>Histórico de pagamentos</p>',
    fr: '<p>Historique de paiements</p>',
    eus: '<p>Ordainketen historia</p>',
    cat: '<p>Historial de pagament</p>',
  },

  myPromotions_data21: {
    es: '<p>Suscripciones activas</p>',
    en: '<p>Active subscriptions</p>',
    pt: '<p>Subscrições ativas</p>',
    fr: '<p>Abonnements actifs</p>',
    eus: '<p>Harpidetza aktiboak</p>',
    cat: '<p>Subscripcions actives</p>',
  },

  myPromotions_data22: {
    es: '<p>Método de pago</p>',
    en: '<p>Payment method</p>',
    pt: '<p>Método de pagamento</p>',
    fr: '<p>Mode de paiement</p>',
    eus: '<p>Ordainketa metodoa</p>',
    cat: '<p>Mètode de pagament</p>',
  },

  adminRole: {
    es: '<p>Admin</p>',
    en: '<p>Admin</p>',
    pt: '<p>Administrador</p>',
    fr: '<p>Admin</p>',
    eus: '<p>Admin</p>',
    cat: '<p>Admin</p>',
  },
  dptAdminRole: {
    es: '<p>Jefe DPTO</p>',
    en: '<p>Head of Dept.</p>',
    pt: '<p>Chefe de DEPARTAMENTO</p>',
    fr: '<p>Chef Dépt.</p>',
    eus: '<p>Dptu. burua</p>',
    cat: '<p>Cap del departament</p>',
  },
  userRole: {
    es: '<p>Usuario</p>',
    en: '<p>User</p>',
    pt: '<p>Utilizador</p>',
    fr: '<p>Utilisateur</p>',
    eus: '<p>Erabiltzailea</p>',
    cat: '<p>Usuari</p>',
  },
  superAdminRole: {
    es: '<p>Super Admin</p>',
    en: '<p>Super Admin</p>',
    pt: '<p>Superadministrador</p>',
    fr: '<p>Super Admin</p>',
    eus: '<p>Super Admin.</p>',
    cat: '<p>Superadmin</p>',
  },
  cuentaEmpresa_usuarios_data7: {
    es: '<p>Transferir</p>',
    en: '<p>Transfer</p>',
    pt: '<p>Transferir</p>',
    fr: '<p>Transférer</p>',
    eus: '<p>Transferitu</p>',
    cat: '<p>Transfereix</p>',
  },
  cuentaEmpresa_usuarios_data8: {
    es: '<p>Eliminar</p>',
    en: '<p>Delete</p>',
    pt: '<p>Eliminar</p>',
    fr: '<p>Supprimer</p>',
    eus: '<p>Ezabatu</p>',
    cat: '<p>Elimina</p>',
  },
  cuentaEmpresa_usuarios_data9: {
    es: '<p>Editar</p>',
    en: '<p>Edit</p>',
    pt: '<p>Editar</p>',
    fr: '<p>Éditer</p>',
    eus: '<p>Editatu</p>',
    cat: '<p>Modifica</p>',
  },
  cuentaEmpresa_usuarios_data10: {
    es: '<p>Sin Categorizar</p>',
    en: '<p>Uncategorized</p>',
    pt: '<p>Sem categorizar</p>',
    fr: '<p>Sans catégorisation</p>',
    eus: '<p>Kategorizatu gabe</p>',
    cat: '<p>Sense categoritzar</p>',
  },
  maintance_data1: {
    es: '<p>¡Hola! :)</p>',
    en: '<p>Hi! :)</p>',
    pt: '<p>Olá! :)</p>',
    fr: '<p>Bonjour ! :-)</p>',
    eus: '<p>Kaixo! :)</p>',
    cat: '<p>Hola! :)</p>',
  },
  maintance_data2: {
    es: '<p>Gracias por visitar nuestra página.</p>',
    en: '<p>Thanks for visiting our page.</p>',
    pt: '<p>Obrigado por visitar a nossa página.</p>',
    fr: '<p>Nous vous remercions de visiter notre site web.</p>',
    eus: '<p>Eskerrik asko gure webgunea bisitatzeagatik.</p>',
    cat: '<p>Gràcies per la visita.</p>',
  },
  maintance_data3: {
    es: '<p>En este momento estamos realizando labores de mantenimiento para mejorar tu experiencia. Por favor, vuelve a visitarnos pronto para disfrutar de nuestro contenido actualizado. <span class="i18n-bold">¡Gracias por tu comprensión y paciencia!<span></p>',
    en: '<p>We are currently performing updates to improve your experience. Please come back soon to enjoy our new content. <span class="i18n-bold">Thank you for your patience and understanding!<span></p>',
    pt: '<p>Neste momento estamos a realizar trabalhos de manutenção para melhorar a sua experiência. Volte a visitar-nos em breve para desfrutar do nosso conteúdo. <span class="i18n-bold">Agradecemos a sua compreensão e paciência!<span></p>',
    fr: '<p>Nous effectuons actuellement des travaux de maintenance afin d’améliorer votre expérience utilisateur. Nous vous invitons donc à revenir bientôt pour profiter d’un contenu actualisé. <span class="i18n-bold">Nous vous remercions de votre compréhension et patience !<span></p>',
    eus: '<p>Une honetan, mantentze lanak egiten ari gara zure esperientzia hobetzeko. Berriz bisita gaitzazu laster, gure webguneko eduki eguneratuaz gozatu ahal izateko. <span class="i18n-bold">Eskerrik asko zure ulermen eta pazientziagatik!<span></p>',
    cat: '<p>En aquests moments estem duent a terme tasques de manteniment per millorar la teva experiència. Torna a visitar-nos aviat per gaudir del contingut més actualitzat. <span class="i18n-bold">Gràcies per la comprensió i la paciència!<span></p>',
  },
  myPromotions_data23: {
    es: '<p>Tarjeta débito</p>',
    en: '<p>Debit card</p>',
    pt: '<p>Cartão de débito</p>',
    fr: '<p>Carte de débit</p>',
    eus: '<p>Zordunketa txartela</p>',
    cat: '<p>Targeta dèbit</p>',
  },
};
