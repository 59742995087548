import { Groups } from './groups';
import { ToolConfig } from './tool-config';
import { Tools } from './tools';
import {
  ALL_ADDONS_IMAGE_TOOLS,
  ALL_ADDONS_SECURITY_TOOLS,
  ALL_MEASURE_TOOLS,
  ALL_MODIFY_TOOLS,
  ALL_MOVEMENT_TOOLS,
  ALL_SCALE_TOOLS,
  ALL_VIEW_TOOLS,
} from './tools-by-group';

/**
 * Array that defines the configuration for all tools on the platform. This configuration is more closely related to the right panel, since
 * left panel configurations are mostly defined on the *ButtonsConfig* array (for both groups and tools).
 *
 * Each tool has the following attributes in its configuration:
 * * tool. Identifies the tool.
 * * headerTextKeys. Contains the i18n key related to the text displayed on the tool header.
 * * compatibleGroups. Determines which groups can be opened while maintaining the tool open on the right panel. A tool can be opened
 * inside a group it's not compatible with (although it is advised to include the group the tool button is on as a compatible group), but
 * if we were to try to move to that group while the tool is opened it would close the tool before moving to that group.
 * * compatibleTools. Specifies which tools can be opened at the same time as the current tool. It's important to note that this
 * compatibility is only in one direction. For example, if the configuration for a tool *A* includes the tool *B* has a compatible
 * tool, click on tool *B* while tool *A* is active will result on both tools panels being open at the same time. However, if we
 * were to click on tool *A* while tool *B* is active (considering that tool *B* has no tools added to its compatibility array),
 * then tool *B* would be closed and tool *A* would be opened, resulting on only the last tool selected being selected.
 * * usesPanel. Boolean flag used to identify if the tool uses a right panel or not. This is mainly used to differentiate
 * standard tools (such as measure, scale, etc.) from specific tools (zoom, multicam, etc.).
 *
 */
export const ToolsConfig: ToolConfig[] = [
  {
    tool: Tools.MEASURE,
    headerTextKeys: ['visores_PanelDerecho_MedidasMultiples_Medida_data2'],
    compatibleGroups: [Groups.STANDARD, Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/measure/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.HEIGHT,
    headerTextKeys: ['toolbar_height'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/height/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.AREA,
    headerTextKeys: ['toolbar_area'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/area/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.VOLUME,
    headerTextKeys: ['toolbar_volume'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/volume/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.SURFACE,
    headerTextKeys: ['visores_PanelDerecho_Superficies_data16'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/surface/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.MULTIPLE,
    headerTextKeys: ['multiple_measures_header'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/multiple/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.CONTOUR,
    headerTextKeys: ['toolbar_contour'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/contour/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.ANGLE,
    headerTextKeys: ['visores_PanelDerecho_Angulo_data1'],
    compatibleGroups: [Groups.MEASUREMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/angle/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.SCALE,
    headerTextKeys: ['toolGroupScale'],
    compatibleGroups: [Groups.SCALE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/scale/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.COORDINATE,
    headerTextKeys: ['toolbar_coordinate'],
    compatibleGroups: [Groups.SCALE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/coordinate/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.REFERENCE_SYSTEM,
    headerTextKeys: ['visores_PanelDerecho_SistemaReferencia_data12'],
    compatibleGroups: [Groups.SCALE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/reference-system/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.GEOREFERENCE_SYSTEM,
    headerTextKeys: ['toolbar_georeferencing_system_title'],
    compatibleGroups: [Groups.SCALE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon:
      'assets/images/viewer-new/tools/georeference-system/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.FLOOR,
    headerTextKeys: ['toolbar_floor_plane'],
    compatibleGroups: [Groups.SCALE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/floor/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.Z_AXIS,
    headerTextKeys: ['visores_PanelDerecho_EjeZ_data1'],
    compatibleGroups: [Groups.SCALE],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/z-axis/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.NOTES,
    headerTextKeys: ['toolbar_notes'],
    compatibleGroups: [Groups.MODIFY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/notes/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.CLIPPING,
    headerTextKeys: ['toolbar_clipping'],
    compatibleGroups: [Groups.MODIFY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/clippings/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.THREE_D_MATCHING,
    headerTextKeys: ['toolbar_match_models'],
    compatibleGroups: [Groups.SECURITY, Groups.ENGINEERING],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/3d-matching/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.MODEL_JOIN,
    headerTextKeys: ['toolbar_join_models'],
    compatibleGroups: [Groups.MODIFY, Groups.SECURITY, Groups.ENGINEERING],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/model-join/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.OBJECT_SELECTION,
    headerTextKeys: ['toolbar_selection'],
    compatibleGroups: [Groups.MODIFY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/object-selection/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.SECTION,
    headerTextKeys: ['toolbar_section'],
    compatibleGroups: [Groups.MODIFY],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/section/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.PROJECTION,
    headerTextKeys: ['toolGroupVisualization'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/projection/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.ELEVATION_MAP,
    headerTextKeys: ['toolbar_elevation_map'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/elevation-map/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.VERTEX_POINTS,
    headerTextKeys: ['toolbar_vertexs_points', 'toolbar_vertexs'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/vertex-points/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.VIRTUAL_TOUR,
    headerTextKeys: ['toolbar_virtual_tour'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/virtual-tour/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.SEGMENTATION,
    headerTextKeys: ['toolbar_segmentation_objects'],
    compatibleGroups: [Groups.SECURITY, Groups.ENGINEERING],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/segmentation/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.INITIAL_POSITION,
    headerTextKeys: ['toolbar_establish_initial_position'],
    compatibleGroups: [Groups.MOVEMENT],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/initial-position/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.ROTATION_CENTER,
    headerTextKeys: ['toolbar_rotation_center'],
    compatibleGroups: [Groups.MOVEMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/rotation-center/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.RESTORE,
    headerTextKeys: ['visores_PanelIzquierdo_data45'],
    compatibleGroups: [Groups.MOVEMENT],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/restore/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.DEFORMATION_ENERGY,
    headerTextKeys: ['visores_PanelIzquierdo_data48'],
    compatibleGroups: [Groups.SECURITY],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/deformation-energy/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.THREE_D_PROJECTION,
    headerTextKeys: ['visores_PanelDerecha_Adds_Proyecciónen3D_data1'],
    compatibleGroups: [
      Groups.SECURITY,
      Groups.ENGINEERING,
      Groups.STANDARD,
      Groups.ADVANCED,
      Groups.MEASUREMENT,
    ],
    compatibleTools: [
      Tools.ZOOM,
      Tools.ZOOM_PANEL,
      Tools.MEASURE,
      Tools.HEIGHT,
    ],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/3d-projection/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.BLOOD_SPLATTER,
    headerTextKeys: ['visores_PanelDerecha_Adds_TrayectoriaSangre_data1'],
    compatibleGroups: [Groups.SECURITY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/blood-splatter/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.BULLET_PATH,
    headerTextKeys: ['visores_PanelDerecho_TrayectoriaBalas_data1'],
    compatibleGroups: [Groups.SECURITY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/bullet-trajectory/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.INTERIOR_BLUEPRINTS,
    headerTextKeys: ['visores_PanelDerecho_PlanosInteriores_data1'],
    compatibleGroups: [Groups.SECURITY, Groups.ENGINEERING],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon:
      'assets/images/viewer-new/tools/interior-blueprints/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.EXTERIOR_INTERIOR,
    headerTextKeys: ['visores_PanelDerecha_Adds_ExteriorInterior_data1'],
    compatibleGroups: [Groups.SECURITY, Groups.ENGINEERING, Groups.AGRICULTURE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/exterior-interior/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.OFFLINE_VIEWER,
    headerTextKeys: ['visores_PanelDerecho_VisorOffline_data1'],
    compatibleGroups: [
      Groups.SECURITY,
      Groups.ENGINEERING,
      Groups.AGRICULTURE,
      Groups.STANDARD,
      Groups.ADVANCED,
      Groups.MODIFY,
    ],
    compatibleTools: [Tools.ZOOM, Tools.NOTES],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/offline-viewer/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.POINT_TO_POINT,
    headerTextKeys: ['toolGroupProcessingImages'],
    compatibleGroups: [Groups.IMAGES, Groups.SCALE],
    compatibleTools: [Tools.ZOOM, Tools.SCALE],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/point-to-point/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.PHOTORECTIFICATION,
    headerTextKeys: ['photo_readjustment_name'],
    compatibleGroups: [Groups.IMAGES],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/photorectification/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.LANGUAGE_SELECTOR,
    headerTextKeys: ['new_Left_Panel_Data61'],
    compatibleGroups: [],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/language-selection/icon.svg',
    isToggleable: true,
  },
  {
    tool: Tools.BACKGROUND_COLOR,
    headerTextKeys: ['new_Left_Panel_Data60'],
    compatibleGroups: [],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/background-color/icon.svg',
    isToggleable: true,
  },
  {
    tool: Tools.SHARE,
    headerTextKeys: ['new_Left_Panel_Data59'],
    compatibleGroups: [],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/share/icon.svg',
    isToggleable: true,
  },
  {
    tool: Tools.FULLSCREEN,
    headerTextKeys: [''],
    compatibleGroups: [],
    compatibleTools: [],
    usesPanel: false,
    minimizedIcon: 'assets/images/viewer-new/tools/fullscreen/icon.svg',
    isToggleable: true,
  },
  {
    tool: Tools.RECORD,
    headerTextKeys: [''],
    compatibleGroups: [
      Groups.STANDARD,
      Groups.ADVANCED,
      Groups.MEASUREMENT,
      Groups.SCALE,
      Groups.MODIFY,
      Groups.VIEW,
      Groups.MOVEMENT,
      Groups.IOTA,
      Groups.IMAGES,
      Groups.ADDONS,
      Groups.SECURITY,
      Groups.ENGINEERING,
      Groups.AGRICULTURE,
      Groups.MULTILAYER,
    ],
    compatibleTools: [
      ...ALL_MEASURE_TOOLS,
      ...ALL_SCALE_TOOLS,
      ...ALL_MODIFY_TOOLS,
      ...ALL_MOVEMENT_TOOLS,
      ...ALL_VIEW_TOOLS,
      ...ALL_ADDONS_IMAGE_TOOLS,
      ...ALL_ADDONS_SECURITY_TOOLS,
    ],
    usesPanel: false,
    minimizedIcon: 'assets/images/viewer-new/tools/rec/icon.svg',
    isToggleable: true,
  },
  {
    tool: Tools.FILTERS,
    headerTextKeys: ['new_Left_Panel_Data53'],
    compatibleGroups: [],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/filters/icon.svg',
    isToggleable: true,
  },
  {
    tool: Tools.ZOOM,
    headerTextKeys: [''],
    compatibleGroups: [],
    compatibleTools: [
      ...ALL_MEASURE_TOOLS,
      ...ALL_SCALE_TOOLS,
      ...ALL_MODIFY_TOOLS,
      ...ALL_MOVEMENT_TOOLS,
      ...ALL_VIEW_TOOLS,
      ...ALL_ADDONS_IMAGE_TOOLS,
      ...ALL_ADDONS_SECURITY_TOOLS,
    ],
    usesPanel: false,
    minimizedIcon: '',
    isToggleable: false,
  },
  {
    tool: Tools.INCIDENCES,
    headerTextKeys: ['new_Left_Panel_Data62'],
    compatibleGroups: [Groups.ADVANCED],
    compatibleTools: [Tools.Z_AXIS, Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/incidences/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.REDUCED_VIRTUAL_TOUR,
    headerTextKeys: ['toolbar_reduced_virtual_tour'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/virtual-tour/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.IOTA_DOWNLOAD,
    headerTextKeys: ['new_Left_Panel_Data79'],
    compatibleGroups: [Groups.IOTA],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/iota-download/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.IOTA_IMAGES,
    headerTextKeys: ['new_Left_Panel_Data81'],
    compatibleGroups: [Groups.IOTA],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/iota-images/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.IOTA_PROCESS,
    headerTextKeys: ['new_Left_Panel_Data80'],
    compatibleGroups: [Groups.IOTA],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/iota-process/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.MAP,
    headerTextKeys: ['new_Left_Panel_Data55'],
    compatibleGroups: [Groups.ADVANCED, Groups.STANDARD, Groups.SCALE],
    compatibleTools: [Tools.GEOREFERENCE_SYSTEM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/map/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.THICKNESS,
    headerTextKeys: ['new_Left_Panel_Data65'],
    compatibleGroups: [Groups.SECURITY, Groups.ENGINEERING, Groups.AGRICULTURE],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/thickness/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.SENSORIC,
    headerTextKeys: ['new_Left_Panel_Data56'],
    compatibleGroups: [
      Groups.SECURITY,
      Groups.ENGINEERING,
      Groups.ADVANCED,
      Groups.STANDARD,
      Groups.SCALE,
    ],
    compatibleTools: [Tools.GEOREFERENCE_SYSTEM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/sensoric/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.HYPERSPECTRAL,
    headerTextKeys: ['new_Left_Panel_Data57'],
    compatibleGroups: [
      Groups.SECURITY,
      Groups.ENGINEERING,
      Groups.STANDARD,
      Groups.ADVANCED,
      Groups.MEASUREMENT,
      Groups.ADDONS,
    ],
    compatibleTools: [Tools.MEASURE, Tools.AREA, Tools.VOLUME],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/hyperspectral/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.IFC,
    headerTextKeys: ['new_Left_Panel_Data64'],
    compatibleGroups: [Groups.ENGINEERING],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/ifc/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.VEGETATION_CALCULATION,
    headerTextKeys: ['visores_PanelDerecha_Adds_CalculoVegetacion_data1'],
    compatibleGroups: [Groups.ENGINEERING, Groups.AGRICULTURE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon:
      'assets/images/viewer-new/tools/vegetation-calculation/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.FIRST_PERSON,
    headerTextKeys: ['new_Left_Panel_Data73'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/first-person/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.VR,
    headerTextKeys: ['new_Left_Panel_Data82'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [Tools.ZOOM],
    usesPanel: false,
    minimizedIcon: 'assets/images/viewer-new/tools/vr/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.TRAFFIC_ANIMATION,
    headerTextKeys: ['new_Left_Panel_Data83'],
    compatibleGroups: [Groups.VIEW],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/traffic-animation/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.RUN_OVER,
    headerTextKeys: ['new_Left_Panel_Data84'],
    compatibleGroups: [Groups.SECURITY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/run-over-analysis/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.BRAKING_FOOTPRINT,
    headerTextKeys: ['new_Left_Panel_Data85'],
    compatibleGroups: [Groups.SECURITY],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/braking-footprint/icon.svg',
    isToggleable: false,
  },
  {
    tool: Tools.ORTHOMOSAIC,
    headerTextKeys: ['new_Left_Panel_Data86'],
    compatibleGroups: [Groups.AGRICULTURE],
    compatibleTools: [Tools.ZOOM],
    usesPanel: true,
    minimizedIcon: 'assets/images/viewer-new/tools/orthomosaic/icon.svg',
    isToggleable: false,
  },
];
