import { NgModule } from '@angular/core';
import { LayoutComponent } from './components/layout/layout.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ModelProgressBarComponent } from './components/model-progress-bar/model-progress-bar.component';
import { I18nService } from '../core/services/i18n.service';
import { LoaderComponent } from './components/loader/loader.component';
import { GalleryVisualizatorComponent } from './components/gallery-visualizator/gallery-visualizator.component';
import { GallerySliderComponent } from './components/gallery-slider/gallery-slider.component';
import { GalleryItemComponent } from './components/gallery-slider/gallery-item/gallery-item.component';
import { MediaFilesGalleryComponent } from './components/media-files-gallery/media-files-gallery.component';
import { MediaFileItemComponent } from './components/media-files-gallery/media-file-item/media-file-item.component';
import { StaticSliderComponent } from './components/static-slider/static-slider.component';
// import { SimplebarAngularModule } from 'simplebar-angular';
import { MarkUnmarkModelAsFavoriteComponent } from './components/mark-unmark-model-as-favorite/mark-unmark-model-as-favorite.component';
import { AdsenseModule } from 'ng2-adsense';
import { GoogleAdsenseComponent } from './components/google-adsense/google-adsense.component';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nPipe } from './pipes/i18n.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { I18nDirective } from './directives/i18n.directive';
import { AngularMaterialModule } from './modules/angular-material.module';
import { NcConsentComponent } from './components/cookie-consent/nc-consent.component';
import { IotaNoticeComponent } from './components/iota-notice/iota-notice.component';
import { ShowModelMediaFilesDialogComponent } from './components/show-model-media-files-dialog/show-model-media-files-dialog.component';
import { RouterModule } from '@angular/router';
import { SliderComponent } from './components/slider/slider.component';
import { PopularsItemComponent } from './components/populars-slider/populars-item/populars-item.component';
import { PopularsSliderComponent } from './components/populars-slider/populars-slider.component';
import { GalleryByCategoriesComponent } from './components/gallery-by-categories/gallery-by-categories.component';
import { InformationPageComponent } from '../components/information-page/information-page.component';
import { MoreItemComponent } from './components/populars-slider/more-item/more-item.component';
import { GalleryModelSliderComponent } from './components/gallery-model-slider/gallery-model-slider.component';
import { GalleryModelItemComponent } from './components/gallery-model-slider/gallery-model-item/gallery-model-item.component';
import { GalleryByCategoryModelItemComponent } from './components/gallery-by-categories/gallery-by-category-model-item/gallery-by-category-model-item.component';
import { LimitedVersionDialogComponent } from './components/limited-version-dialog/limited-version-dialog.component';
import { CountriesSelectorComponent } from './components/countries-selector/countries-selector.component';
// import {Select2Module} from 'ng-select2-component';
import { ThenItemComponent } from './components/then-item/then-item.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { COMMONS_I18N_ENTRIES } from './config/commons-i18n-entries';
import { PeriodPipe } from './pipes/period.pipe';
import { INFORMATION_PAGE_USE_I18N_ENTRIES } from '../config/information-page-use-i18n-entries';
import { INFORMATION_PAGE_PRIVACY_I18N_ENTRIES } from '../config/information-page-privacy-i18n-entries';
import { INFORMATION_PAGE_COOKIES_I18N_ENTRIES } from '../config/information-page-cookies-i18n-entries';
import { HEADER_I18N_ENTRIES } from '../config/header-i18n-entries';
import { FOOTER_I18N_ENTRIES } from '../config/footer-i18n-entries';
import { HOME_I18N_ENTRIES } from '../config/home-i18n-entries';
import { IOTA_DIALOG_I18N_ENTRIES } from '../config/iota-dialog-i18n-entries';
import { COOKIE_DIALOG_I18N_ENTRIES } from '../config/cookie-dialog-i18n-entries';
import { VIEWPROJECT_I18N_ENTRIES } from '../config/viewproject-i18n-entries';
import { VIEWPROJECT_INCIDENCES_I18N_ENTRIES } from '../config/viewproject-incidences-i18n-entries';
import { ChatHelpComponent } from './components/chat-help/chat-help.component';
import { JoinRequestActionComponent } from './components/join-request-action/join-request-action.component';
import { TermsOfUseComponent } from '../components/information-text/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from '../components/information-text/privacy-policy/privacy-policy.component';
import { CookiesPolicyComponent } from '../components/information-text/cookies-policy/cookies-policy.component';
import { SECTORS_I18N_ENTRIES } from './config/sectors-i18n-entries';
import { ConfirmModelsDownloadComponent } from './components/confirm-models-download/confirm-models-download.component';
import { GALLERY_DIALOGS_I18N_ENTRIES } from './config/gallery-dialogs-i18n-entries';
import { InformationWithIconGalleryDialogComponent } from './components/information-with-icon-gallery-dialog/information-with-icon-gallery-dialog.component';
import { InformationWithIconAndTwoParagraphGalleryDialogComponent } from './components/information-with-icon-and-two-paragraph-gallery-dialog/information-with-icon-and-two-paragraph-gallery-dialog.component';
import { InformationWithTwoButtonsGalleryDialogComponent } from './components/information-with-two-buttons-gallery-dialog/information-with-two-buttons-gallery-dialog.component';
import { InformationWithTextAreaGalleryDialogComponent } from './components/information-with-text-area-gallery-dialog/information-with-text-area-gallery-dialog.component';
import { ChangesSavedDialogComponent } from './components/changes-saved-dialog/changes-saved-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiringComponent } from '../components/hiring/hiring.component';
import { HIRING_I18N_ENTRIES } from '../config/hiring-i18n-entries';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    SliderComponent,
    LayoutComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    ModelProgressBarComponent,
    PopularsItemComponent,
    PopularsSliderComponent,
    LoaderComponent,
    GalleryByCategoriesComponent,
    GalleryVisualizatorComponent,
    GallerySliderComponent,
    GalleryItemComponent,
    MediaFilesGalleryComponent,
    MediaFileItemComponent,
    StaticSliderComponent,
    MarkUnmarkModelAsFavoriteComponent,
    GoogleAdsenseComponent,
    I18nPipe,
    PeriodPipe,
    SplitPipe,
    I18nDirective,
    NcConsentComponent,
    IotaNoticeComponent,
    ShowModelMediaFilesDialogComponent,
    InformationPageComponent,
    MoreItemComponent,
    GalleryModelSliderComponent,
    GalleryModelItemComponent,
    GalleryByCategoryModelItemComponent,
    LimitedVersionDialogComponent,
    CountriesSelectorComponent,
    ThenItemComponent,
    MaintenanceComponent,
    ChatHelpComponent,
    JoinRequestActionComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    CookiesPolicyComponent,
    HiringComponent,
    ConfirmModelsDownloadComponent,
    InformationWithIconGalleryDialogComponent,
    InformationWithIconAndTwoParagraphGalleryDialogComponent,
    InformationWithTwoButtonsGalleryDialogComponent,
    InformationWithTextAreaGalleryDialogComponent,
    ChangesSavedDialogComponent,
  ],
  imports: [
    AdsenseModule.forRoot(),
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    // Select2Module,
  ],
  exports: [
    LayoutComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    LanguageSelectorComponent,
    ModelProgressBarComponent,
    LoaderComponent,
    MediaFilesGalleryComponent,
    StaticSliderComponent,
    // SimplebarAngularModule,
    GoogleAdsenseComponent,
    AdsenseModule,
    I18nPipe,
    PeriodPipe,
    SplitPipe,
    MatMenuModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    I18nDirective,
    NcConsentComponent,
    IotaNoticeComponent,
    ShowModelMediaFilesDialogComponent,
    SliderComponent,
    PopularsSliderComponent,
    GalleryVisualizatorComponent,
    InformationPageComponent,
    GalleryModelSliderComponent,
    GalleryByCategoriesComponent,
    GalleryModelItemComponent,
    CountriesSelectorComponent,
    ThenItemComponent,
    ChatHelpComponent,
  ],
})
export class CommonsModule {
  constructor(private i18nService: I18nService) {
    i18nService.addI18nEntriesObject(COMMONS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(INFORMATION_PAGE_USE_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(INFORMATION_PAGE_PRIVACY_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(HIRING_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(INFORMATION_PAGE_COOKIES_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(HEADER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(FOOTER_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(HOME_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(IOTA_DIALOG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(COOKIE_DIALOG_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(VIEWPROJECT_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(VIEWPROJECT_INCIDENCES_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(SECTORS_I18N_ENTRIES);
    i18nService.addI18nEntriesObject(GALLERY_DIALOGS_I18N_ENTRIES);
  }
}
