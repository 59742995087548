export const HEADER_I18N_ENTRIES = {

  header_Data1: {
    es: '<p>Nuevo Modelo 3D</p>',
    en: '<p>New 3D Model</p>',
    pt: '<p>Novo modelo 3D</p>',
    fr: '<p>Nouveau modèle 3D</p>',
    eus: '<p>3D eredu berria</p>',
    cat: '<p>Nou model 3D</p>',
  },

  header_Data2: {
    es: '<p>Galería</p>',
    en: '<p>Gallery</p>',
    pt: '<p>Galeria</p>',
    fr: '<p>Galerie</p>',
    eus: '<p>Galeria</p>',
    cat: '<p>Galeria</p>',
  },

  header_Data3: {
    es: '<p>Configura tu cuenta</p>',
    en: '<p>Set up your account</p>',
    pt: '<p>Configure a sua conta</p>',
    fr: '<p>Configurez votre compte</p>',
    eus: '<p>Konfiguratu zure kontua</p>',
    cat: '<p>Configura el compte</p>',
  },

  header_Data4: {
    es: '<p>Ayuda</p>',
    en: '<p>Help</p>',
    pt: '<p>Ajuda</p>',
    fr: '<p>Assistance</p>',
    eus: '<p>Laguntza</p>',
    cat: '<p>Ajuda</p>',
  },

  header_Data5: {
    es: '<p>Cerrar sesión</p>',
    en: '<p>Log-out</p>',
    pt: '<p>Terminar sessão</p>',
    fr: '<p>Se déconnecter</p>',
    eus: '<p>Itxi saioa</p>',
    cat: '<p>Tanca sessió</p>',
  },
  header_Data6: {
    es: '<p>Menú</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  header_Data7: {
    es: '<p>Mi cuenta</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
  header_Data8: {
    es: '<p>Centro de Ayuda</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },
};
