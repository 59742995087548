import { Injectable } from '@angular/core';
import { Model } from '../../../models/model';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModelService } from '../model/model.service';
import { AuthenticationService } from '../../../services/security/authentication.service';
import { User } from '../../../commons/models/user/user';
import { ResponsiveService } from '../responsive.service';
import { GalleryCategoryEnum } from 'src/app/models/common/gallery-category-enum';
import { DeviceType } from 'src/app/models/common/device-type';
import { GalleryRequest } from 'src/app/models/gallery-responsive';

type ModelsByGallery = {
  search: string;
  categories: any[];
  subcategories: any[];
  department: any[];
  filterBy: any[];
  currentPage: number;
  requests: any[];
  screenSize: number[];
};

export type ModelRequest = {
  current_page: number;
  data: Model[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: ModelRequestLink[];
  next_page_url: string;
  path: string;
  per_page: string;
  prev_page_url: string;
  to: number;
  total: string;
};

type ModelRequestLink = {
  active: boolean;
  label: string;
  url: string;
};

enum OptionsEnum {
  SLIDER,
  GRID,
  BOTH
}

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  user: User;
  search = '';
  categories: any[] = [];
  subcategories: any[] = [];

  cDevice: DeviceType;
  currentPages: number[] = [];
  extraModels: GalleryRequest[] = [];
  specialCase = false;
  lastPague = false;
  department: any[] = [];
  filterBy: any[] = [];
  selectedModels: any[] = [];
  initialGalleryRequested = false;

  currentGallery: GalleryCategoryEnum = GalleryCategoryEnum.PORTFOLIO;

  selectedModels$ = new Subject<Model[]>();
  gridModels$ = new BehaviorSubject<ModelRequest>(null);
  sliderModels$ = new BehaviorSubject<[]>([]);
  allModels$ = new BehaviorSubject<ModelRequest>(null);

  private readonly MODELS_TO_LOAD_MOBILE = 7;
  private readonly MODELS_TO_LOAD_TABLET_LANDSCAPE = 15;
  private readonly MODELS_TO_LOAD_TABLET = 17;
  private readonly MODELS_TO_LOAD_PC = 15;

  private isInViewer = false;

  private loadedModels: ModelsByGallery[][] = [];

  constructor(
    private modelService: ModelService,
    private responsiveService: ResponsiveService,
    private authenticationService: AuthenticationService
  ) {
    this.initializeLoadedModels();
    this.setCurrentDeviceType();
    this.authenticationService.OnLogout.subscribe(() => {
      this.initializeLoadedModels();
    });
  }

  public getGridModels() {
    return this.gridModels$.pipe(filter((ele) => ele !== null));
  }

  public setIsInViewer(isInViewer: boolean) {
    this.isInViewer = isInViewer;
  }

  private initializeLoadedModels() {
    for (const item in GalleryCategoryEnum) {
      if (isNaN(Number(item))) {
        this.loadedModels[Number(GalleryCategoryEnum[item]) - 1] = [];
        this.currentPages[Number(GalleryCategoryEnum[item]) - 1] = 1;
      }
    }
  }

  updateSelectedModels(model: Model) {
    const index = this.selectedModels.findIndex(
      (selectedModel) => selectedModel.id === model.id
    );
    if (index !== -1) {
      // Si el modelo ya existe en el array, lo eliminamos
      this.selectedModels.splice(index, 1);
    } else {
      // Si el modelo no existe en el array, lo agregamos
      this.selectedModels.push(model);
    }
    this.selectedModels$.next(this.selectedModels);
  }
  resetSelectedModels() {
    this.selectedModels = [];
    this.selectedModels$.next(this.selectedModels);
  }

  filterModels(value: string) {
    this.search = value;
    this.customSearch(value);
  }

  goToPage(page: number = 1) {
    this.currentPages[this.currentGallery - 1] = page;
    switch (this.currentGallery) {
      case GalleryCategoryEnum.POPULAR:
        this.getPopular(page);
        break;
      case GalleryCategoryEnum.PORTFOLIO:
        this.getPorfolio(page);
        break;
      case GalleryCategoryEnum.FAVORITES:
        this.getFavorites(page);
        break;
      case GalleryCategoryEnum.OTHER:
      case GalleryCategoryEnum.LAST_MODELS:
        break;
      default:
        break;
    }
  }

  setCurrentGallery(currentGallery: GalleryCategoryEnum) {
    this.currentGallery = currentGallery;
    this.goToPage(this.currentPages[this.currentGallery - 1]);
  }

  setCategorySelected(id: any) {
    this.categories = id;
    this.goToPage();
  }

  private getPopular(page: number, options: OptionsEnum = OptionsEnum.BOTH) {
    const queryIndex = this.checkIfQueryExists();
    let searchPage = -1;

    if (queryIndex !== -1) {
      searchPage = this.loadedModels[this.currentGallery - 1][
        queryIndex
      ].requests.findIndex((request) => request.meta.current_page === page);
    }

    if (queryIndex !== -1 && searchPage !== -1) {
      const query = this.loadedModels[this.currentGallery - 1][queryIndex];
      this.gridModels$.next(query.requests[searchPage]);
    } else {
      this.modelService
        .getPopularModels(
          this.categories,
          this.modelsToLoad(),
          page,
          this.search
        )
        .subscribe((populars: any) => {
          this.processRequest(queryIndex, populars, page, options);
        });
    }
  }

  private getPorfolio(page: number) {
    const queryIndex = this.checkIfQueryExists();
    let searchPage = -1;

    if (queryIndex !== -1) {
      searchPage = this.loadedModels[this.currentGallery - 1][
        queryIndex
      ].requests.findIndex((request) => request.meta.current_page === page);
    }

    if (queryIndex !== -1 && searchPage !== -1) {
      const query = this.loadedModels[this.currentGallery - 1][queryIndex];
      this.gridModels$.next(query.requests[searchPage]);
    } else {
      this.modelService
        .getModelsByUser(
          this.categories,
          this.subcategories,
          this.modelsToLoad(),
          page,
          this.search,
          this.department,
          this.filterBy
        )
        .subscribe((populars: any) => {
          if (populars.data.length > 0) {
            this.processRequest(queryIndex, populars, page);
          } else {
            // this.processRequest(queryIndex, populars, page);
            this.getPopular(1, OptionsEnum.SLIDER);
          }
        });
    }
  }

  private getFavorites(page: number, search: boolean = false) {
    const queryIndex = this.checkIfQueryExists();
    let searchPage = -1;

    if (queryIndex !== -1) {
      searchPage = this.loadedModels[this.currentGallery - 1][
        queryIndex
      ].requests.findIndex((request) => request.current_page === page);
    }

    if (queryIndex !== -1 && searchPage !== -1) {
      const query = this.loadedModels[this.currentGallery - 1][queryIndex];
      this.gridModels$.next(query.requests[searchPage]);
    } else {
      this.modelService
        .getFavoritesModels(
          this.categories.toString(),
          this.modelsToLoad(),
          page,
          this.search,
          this.department.toString(),
          this.filterBy.toString()
        )
        .subscribe((populars: any) => {
          this.processRequest(queryIndex, populars, page);
        });
    }
  }

  customSearch(value: string) {
    this.search = value;
    this.goToPage();
  }

  filterCategory(value: number[]) {
    this.categories = value;
    this.goToPage();
  }

  filterSubcategory(value: number[]) {
    this.subcategories = value;
    this.goToPage();
  }

  filterDepartment(value: number[]) {
    this.department = value;
    this.goToPage();
  }

  filterByMoreFilters(value: number[]) {
    this.filterBy = value;
    this.goToPage();
  }

  resetCategories() {
    this.categories = [];
    this.subcategories = [];
    this.department = [];
    this.filterBy = [];
    this.search = '';
  }

  private checkIfQueryExists(): number {
    return (this.loadedModels[this.currentGallery - 1] as any).findLastIndex(
      (query) =>
        query.search === this.search &&
        this.arraysEqual(query.categories, this.categories) &&
        this.arraysEqual(query.subcategories, this.subcategories) &&
        this.arraysEqual(query.department, this.department) &&
        this.arraysEqual(query.filterBy, this.filterBy)
    );
  }

  private modelsToLoad() {
    let numModels = 0;
    if (this.responsiveService.mobileG()) {
      numModels = this.MODELS_TO_LOAD_MOBILE;
    } else if (
      this.responsiveService.tabletGHorizontal() &&
      this.responsiveService.landscape()
    ) {
      numModels = this.MODELS_TO_LOAD_TABLET_LANDSCAPE;
    } else if (this.responsiveService.tabletG()) {
      numModels = this.MODELS_TO_LOAD_TABLET;
    } else if (this.responsiveService.isPC()) {
      numModels = this.MODELS_TO_LOAD_PC;
    }
    if (this.isInViewer) {
      numModels++;
    }
    return numModels;
  }

  private arraysEqual(a: any, b: any) {
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }

  private setCurrentDeviceType() {
    if (this.responsiveService.newMobile) {
      this.cDevice = DeviceType.mobile;
    } else if (this.responsiveService.newTablet()) {
      this.cDevice = DeviceType.tablet;
    } else {
      this.cDevice = DeviceType.pc;
    }
  }

  /**
   * Método que procesa los datos recibido de la API
   * @param queryIndex Indice a Buscar
   * @param populars Datos recibido de la API
   * @param page paguina a buscar
   */
  private processRequest(
    queryIndex: number | undefined,
    populars: any,
    page: number,
    options: OptionsEnum = OptionsEnum.BOTH
  ) {
    if (queryIndex === -1) {
      this.loadedModels[this.currentGallery - 1].push({
        search: this.search,
        categories: this.categories,
        subcategories: this.subcategories,
        department: this.department,
        filterBy: this.filterBy,
        currentPage: 1,
        requests: [populars],
        screenSize: [],
      });
    } else {
      this.loadedModels[this.currentGallery - 1][queryIndex].requests.push(
        populars
      );
    }

    const query =
      this.loadedModels[this.currentGallery - 1][
      queryIndex === -1
        ? this.loadedModels[this.currentGallery - 1].length - 1
        : queryIndex
      ];

    if (page === 1) {
      let aux = structuredClone(query.requests[query.requests.length - 1]);;
      if (options === OptionsEnum.SLIDER) {
        aux.data = [];
      }
      this.gridModels$.next(aux);
      if (this.search === '' && !this.initialGalleryRequested) {
        this.initialGalleryRequested = true;
        if (options !== OptionsEnum.GRID) {
          this.sliderModels$.next(
            query.requests[query.requests.length - 1].data.slice(0, -4)
          );
        }
      }
    } else {
      let lastModelData = query.requests[query.requests.length - 1].data;
      query.requests[query.requests.length - 1].data = [
        ...this.extraModels,
        ...lastModelData,
      ];
      this.extraModels = [];
      lastModelData = query.requests[query.requests.length - 1].data;
      const elementSlice =
        query.requests[query.requests.length - 1].data.length -
        (this.modelsToLoad() - (page === 1 ? 0 : 1));

      if (elementSlice > 0) {
        this.extraModels = lastModelData.slice(-elementSlice);
        lastModelData.splice(-elementSlice);
      }
      if (options !== OptionsEnum.SLIDER) {
        this.gridModels$.next(query.requests[query.requests.length - 1]);
      }
    }
  }
}
